import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';


let theme = createTheme()
theme = createTheme(theme, {
  palette: {
    type: 'light',
    primary: {
      main: '#662B85',
    },
    secondary: {
      main: '#E42B49',
      gradient: 'linear-gradient(to bottom, #FD627C, #E42B49)'
    },
    error: {
      main: '#E51A1A',
    },
  },
  typography: {
    h1: {
      fontSize: '4rem',
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: "3rem"
      }
    },
    h2: {
      fontSize: '2.3rem',
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: "1.5rem"
      }
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 'normal normal bold'
    },
    h4: {
      fontSize: '1.6rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: "1.3rem"
      }
    },
    h5: {
      fontSize: '1.2rem'
    },
    button: {
      fontSize: '1.2rem',
      textTransform: 'inherit',
    }
  },
  appBar: {
    background: '#fff',
  },
  textField: {
    width: 200,
  },

  cssLabel: {
    color : 'green'
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `green !important`,
    }
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'green !important'
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& $notchedOutline": {
            borderColor: "#fff !important"
          },
          "&:hover $notchedOutline": {
            borderColor: "green"
          },
          "&$focused $notchedOutline": {
            borderColor: "green"
          }
        }  
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // Some CSS
          backgroundColor: '#fff',
          borderRadius: '9px !important'
        },
      },
    },
  }
});


export default theme;
